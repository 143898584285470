import React, { useContext } from 'react';
import './Schedule.css';
import { EU } from '../../assets/json/schedule.json';
import TimerContext from '../../context/timer/timerContext';

const Schedule = () => {
	const { timer } = useContext(TimerContext);
	const { indexOfBoss, dayOfBoss } = timer;
	const currentBossStyle = { backgroundColor: '#004D99', color: 'white' };
	return (
		<div className='table-scroll'>
			<table className='schedule'>
				<thead>
					<tr>
						<th>Time</th>
						<th>Monday</th>
						<th>Tuesday</th>
						<th>Wednesday</th>
						<th>Thursday</th>
						<th>Friday</th>
						<th>Saturday</th>
						<th>Sunday</th>
					</tr>
				</thead>
				<tbody>
					{EU.times.map((time, index) => (
						<tr key={index}>
							<td key={time}>{time}</td>
							{EU.week.map((day) => (
								<td
									key={day.dayInt + day.bosses[index]}
									style={
										day.dayInt === dayOfBoss &&
										index === indexOfBoss
											? currentBossStyle
											: {}
									}
								>
									{day.bosses[index]
										.split(':')
										.map((boss) => (
											<div key={boss}>{boss}</div>
										))}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Schedule;

import { useReducer } from 'react';
import TimerContext from './timerContext';
import TimerReducer from './timerReducer';
import { SET_TIMER } from '../types';

const TimerState = (props) => {
	const initialState = {
		countdown: 'LOOKING FOR',
		upcomingBoss: 'BOSSES',
		upcomingBossImage: 'BdoTimeSpirit.svg',
		indexOfBoss: 0,
		dayOfBoss: null,
	};

	const [state, dispatch] = useReducer(TimerReducer, initialState);

	const setTimer = (countdown, upcomingBoss, indexOfBoss, dayOfBoss) => {
		const upcomingBossImage =
			upcomingBoss.split(':').length >= 1
				? `${upcomingBoss.split(':')[0].toLowerCase()}.png`
				: `${upcomingBoss.toLowerCase()}.png`;

		dispatch({
			type: SET_TIMER,
			payload: {
				countdown,
				upcomingBoss,
				upcomingBossImage,
				indexOfBoss,
				dayOfBoss,
			},
		});
	};

	return (
		<TimerContext.Provider
			value={{
				timer: state,
				setTimer,
			}}
		>
			{props.children}
		</TimerContext.Provider>
	);
};

export default TimerState;

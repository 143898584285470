import React, { useEffect, useContext } from 'react';
import './Bosstimer.css';
import { EU } from '../../assets/json/schedule.json';
import { DateTime } from 'luxon';
import TimerContext from '../../context/timer/timerContext';

const Bosstimer = () => {
	const { timer, setTimer } = useContext(TimerContext);
	let { countdown, upcomingBoss, upcomingBossImage } = timer;

	useEffect(() => {
		const interval = setInterval(() => {
			// const nextBoss = getUpcomingBoss(EU);
			const {
				upcomingBoss,
				countdown,
				nextIndex,
				currentWeekDay,
			} = getUpcomingBoss(EU);
			setTimer(countdown, upcomingBoss, nextIndex, currentWeekDay);
		}, 1000);
		return () => clearInterval(interval);
	}, [countdown, setTimer]);

	return (
		<div className='row align-center'>
			<img
				className='bossImage'
				src={
					require(`../../assets/images/${upcomingBossImage}`).default
				}
				alt='garmoth'
			/>
			<ul className='bosstimer'>
				<li>{countdown}</li>
				<li>
					{upcomingBoss.split(':').map((boss, index) => (
						<span key={boss}>
							{boss}
							{index === 0 &&
								upcomingBoss.split(':').length > 1 &&
								'|'}
						</span>
					))}
				</li>
			</ul>
		</div>
	);
};

const getUpcomingBoss = (schedule) => {
	const timezone = schedule.timezone;
	let currentWeekDay = getCurrentWeekDay(timezone);
	const { nextIndex, nextDay } = getNext(
		schedule,
		getCurrentDate(timezone),
		timezone
	);
	const bossesOfDay = getBossesOfDay(schedule, currentWeekDay, nextDay);
	const upcomingBoss = getBoss(bossesOfDay, nextIndex);
	const nextTime = getBossTime(schedule, nextIndex);
	const countdown = getCountdown(nextTime, nextDay, timezone);

	if (nextDay) {
		if (currentWeekDay + 1 <= 7) {
			currentWeekDay++;
		} else {
			currentWeekDay = 0;
		}
	}

	return { upcomingBoss, countdown, nextIndex, currentWeekDay };
};

const getCountdown = (nextTime, nextDay, timezone) => {
	// create date based on upcomingTime
	let nextBossDate = getCurrentDate(timezone);
	nextBossDate = nextBossDate.set({
		hour: nextTime[0],
		minute: nextTime[1],
		second: 0,
	});
	// if next boss is next day, add one day to date
	if (nextDay) {
		nextBossDate = nextBossDate.plus({ days: 1 });
	}

	const diff = nextBossDate.diff(getCurrentDate(timezone), 'milliseconds');

	return parseCountdown(diff);
};

const getBoss = (bossesOfDay, nextIndex) => {
	return bossesOfDay[nextIndex];
};

const getBossTime = (schedule, nextIndex) => {
	return schedule.timesInt[nextIndex];
};

const getCurrentDate = (timezone) => DateTime.local().setZone(timezone);
const getCurrentWeekDay = (timezone) => getCurrentDate(timezone).get('weekday');

// get index of next time / boss and if next boss spawns next day
// index needed if boss === ''
const getNext = (schedule, currentDate, timezone, afterEmptyIndex) => {
	const hours = currentDate.get('hour');
	const minutes = currentDate.get('minute');
	// const seconds = currentDate.get('second');
	let nextDay = false;
	let nextIndex = 0;
	if (afterEmptyIndex === undefined) {
		// find index of time where spawn hour >= current hour
		nextIndex = schedule.timesInt.findIndex((time) => time[0] >= hours);
	} else {
		nextIndex = afterEmptyIndex;
		if (nextIndex > 8) {
			nextIndex = 0;
			nextDay = true;
		}
		return { nextIndex, nextDay };
	}
	// check if spawn time is expired by minute
	if (
		schedule.timesInt[nextIndex][1] <= minutes &&
		schedule.timesInt[nextIndex][0] === hours
	) {
		// if nextIndex > 8 next spawn is next day
		if (nextIndex + 1 > 8) {
			nextIndex = 0;
			nextDay = true;
		} else {
			nextIndex += 1;
		}
	}

	// check beforehand if last boss of day is empty -> then refer to next day
	if (
		getBossesOfDay(schedule, getCurrentWeekDay(timezone), false)[
			nextIndex
		] === ''
	) {
		return getNext(schedule, currentDate, timezone, ++nextIndex);
	}

	return { nextIndex, nextDay };
};

// get bosses of the current day
const getBossesOfDay = (schedule, currentDay, nextDay) => {
	if (nextDay) {
		if (currentDay + 1 <= 7) {
			currentDay += 1;
		} else {
			currentDay = 1;
		}
	}
	const day = schedule.week.find((day) => day.dayInt === currentDay);
	return day.bosses;
};

// parse time adds 0 before a single digit: X -> 0X
const parseTime = (int) => (int < 10 ? `0${int}` : `${int}`);

const parseCountdown = (millisec) => {
	//millisec = timeZoneCorrection(millisec);
	let seconds = (millisec / 1000).toFixed(0);
	let minutes = parseTime(Math.floor(parseInt(seconds) / 60).toString());
	let hours = '';
	if (parseInt(minutes) > 59) {
		hours = parseTime(Math.floor(parseInt(minutes) / 60).toString());
		minutes = parseTime(
			(parseInt(minutes) - parseInt(hours) * 60).toString()
		);
	}
	seconds = parseTime(Math.floor(parseInt(seconds) % 60).toString());
	if (hours !== '') {
		return `${hours}:${minutes}:${seconds}`;
	}
	return `00:${minutes}:${seconds}`;
};

export default Bosstimer;

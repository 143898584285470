import React from 'react'
import './Brackets.css';
import {ap, dp} from '../../assets/json/brackets.json';

const Brackets = () => {
	return (
		<div className="row justify-center">
			<div className="bracket column align-center">
				<h1>AP Brackets</h1>
				<table>
					<thead>
						<tr>
							<th>Range</th>
							<th>Extra AP</th>
						</tr>
					</thead>
					<tbody>
						{ap.map(bracket => (
							<tr key={bracket.range}>
								<td>{bracket.range}</td>
								<td>{bracket.extra}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="bracket column align-center">
				<h1>DP Brackets</h1>
				<table>
					<thead>
						<tr>
							<th>Range</th>
							<th>Extra DR</th>
						</tr>
					</thead>
					<tbody>
						{dp.map(bracket => (
							<tr key={bracket.range}>
								<td>{bracket.range}</td>
								<td>{bracket.extra}%</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Brackets

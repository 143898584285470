import './App.css';
import Navbar from './layout/navbar/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Schedule from './layout/schedule/Schedule';
import Brackets from './layout/brackets/Brackets';
import Armor from './layout/enhancement/armor/Armor';
import TimerState from './context/timer/TimerState';

const App = () => {
	return (
		<TimerState>
			<Router>
				<div className='App'>
					<Navbar />
					<Switch>
						<Route exact path='/' component={Schedule} />
						<Route exact path='/schedule' component={Schedule} />
						<Route exact path='/brackets' component={Brackets} />
						<Route
							exact
							path='/enhancement/armor'
							component={Armor}
						/>
					</Switch>
				</div>
			</Router>
		</TimerState>
	);
};

export default App;

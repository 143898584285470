import React from 'react';
import '../Enhancement.css';
import { armor, armor_green } from '../../../assets/json/armor.json';

const Armor = () => {
	return (
		<div className='enhancement column justify-center'>
			<div className='column align-center'>
				<h1>White, Blue, Yellow Armor</h1>
				<div className='enhancementTable table-scroll'>
					<table>
						<thead>
							<tr>
								<th>FS</th>
								<th>+6</th>
								<th>+7</th>
								<th>+8</th>
								<th>+9</th>
								<th>+10</th>
								<th>+11</th>
								<th>+12</th>
								<th>+13</th>
								<th>+14</th>
								<th>+15</th>
								<th>PRI</th>
								<th>DUO</th>
								<th>TRI</th>
								<th>TET</th>
								<th>PEN</th>
							</tr>
						</thead>
						<tbody>
							{armor.map((level) => (
								<tr key={level.fs}>
									<td>{level.fs}</td>
									<td>{level.six}%</td>
									<td>{level.seven}%</td>
									<td>{level.eight}%</td>
									<td>{level.nine}%</td>
									<td>{level.ten}%</td>
									<td>{level.eleven}%</td>
									<td>{level.twelve}%</td>
									<td>{level.thirteen}%</td>
									<td>{level.fourteen}%</td>
									<td>{level.fifteen}%</td>
									<td>{level.pri}%</td>
									<td>{level.duo}%</td>
									<td>{level.tri}%</td>
									<td>{level.tet}%</td>
									<td>{level.pen}%</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='column align-center'>
				<h1>Green Armor</h1>
				<div className='enhancementTable table-scroll'>
					<table>
						<thead>
							<tr>
								<th>FS</th>
								<th>+6</th>
								<th>+7</th>
								<th>+8</th>
								<th>+9</th>
								<th>+10</th>
								<th>+11</th>
								<th>+12</th>
								<th>+13</th>
								<th>+14</th>
								<th>+15</th>
								<th>PRI</th>
								<th>DUO</th>
								<th>TRI</th>
								<th>TET</th>
								<th>PEN</th>
							</tr>
						</thead>
						<tbody>
							{armor_green.map((level) => (
								<tr key={level.fs}>
									<td>{level.fs}</td>
									<td>{level.six}%</td>
									<td>{level.seven}%</td>
									<td>{level.eight}%</td>
									<td>{level.nine}%</td>
									<td>{level.ten}%</td>
									<td>{level.eleven}%</td>
									<td>{level.twelve}%</td>
									<td>{level.thirteen}%</td>
									<td>{level.fourteen}%</td>
									<td>{level.fifteen}%</td>
									<td>{level.pri}%</td>
									<td>{level.duo}%</td>
									<td>{level.tri}%</td>
									<td>{level.tet}%</td>
									<td>{level.pen}%</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Armor;

import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Bosstimer from './Bosstimer';
import './Navbar.css';
import Title from '../../assets/images/title_gold.svg';
import Burger from '../../assets/images/burger.svg';

const Navbar = ({ title }) => {
	return (
		<nav className='row align-center justify-spacebetween wrap'>
			<div className='navbar row justify-spacebetween mobile-grow-1'>
				<div className='row justify-spacebetween mobile-grow-1'>
					<div className='burger'>
						<img src={Burger} width='55' alt='' />
					</div>
					<div className='logo mobile-grow-1 mobile-center-fix'>
						<Link to='/'>
							<img src={Title} width='170' alt='' />
							{/* <div className='navbarTitle'>{title}</div> */}
						</Link>
					</div>
				</div>
				<ul className='nav-primary'>
					<Link to='/schedule'>
						<li>
							<h2>Schedule</h2>
						</li>
					</Link>
					<li className='dropdown-opener'>
						<h2>Enhancement</h2>
						<ul className='dropdown-menu column'>
							<Link to='/enhancement/armor'>
								<li>
									<h2>Armor</h2>
								</li>
							</Link>
							<Link to='/enhancement/weapons'>
								<li>
									<h2>Weapons</h2>
								</li>
							</Link>
							<Link to='/enhancement/accessoires'>
								<li>
									<h2>Accessoires</h2>
								</li>
							</Link>
						</ul>
					</li>
					<Link to='/brackets'>
						<li>
							<h2 className=''>Brackets</h2>
						</li>
					</Link>
				</ul>
			</div>
			<div className='row justify-start align-center mobile-grow-1'>
				<div className='select-wrapper'>
					<select name='schedule' id='schedule'>
						<option value='EU'>EU</option>
						<option value='NA'>NA</option>
						<option value='SA'>SA</option>
						<option value='RU'>RU</option>
						<option value='KR'>KR</option>
						<option value='JP'>JP</option>
						<option value='SEA'>SEA</option>
						<option value='MENA'>MENA</option>
						<option value='TH'>TH</option>
						<option value='TW'>TW</option>
					</select>
				</div>
				<div className='mobile-grow-1 justify-center row'>
					<Bosstimer />
				</div>
			</div>
		</nav>
	);
};

Navbar.propTypes = {
	title: PropTypes.string,
};

Navbar.defaultProps = {
	title: 'BDO Stats',
};

export default Navbar;
